export default {
  computed: {
    valueFormat () {
      const { format, showTime } = this.record.options
      return format || (showTime ? `${this.dateFormat} HH:mm:ss` : this.dateFormat)
    }
  },
  methods: {
    /**
     * 时间发生变化的回调，发生在用户选择时间时
     * @param value
     */
    change (value) {
      this.$emit('change', value, this.record.model)
    },
    /**
     * 弹出面板和关闭面板的回调
     * @param status
     */
    openChange (status) {
      this.$emit('openChange', status, this.record.model)
    },
    /**
     * 日期面板变化时的回调（当手动控制面板的收起和弹出时候触发，这里yearPicker用到了）
     * @param value
     */
    panelChange (value) {
      this.$emit('panelChange', value, this.record.model)
    }
  }
}
